<template>
  <dash-page-new
      :title="$store.state.dashboard.pageData.title || $t('Tasks')"
      :subtitle="$t('AllTasks')"
      :icon="$store.state.dashboard.pageData.icon ||  'mdi-check'"
      :side-navigation="navigationItems"
      navigation-route="tasks"
  >

    <template #header_action>

      <ws-button-group
          v-model="$store.state.adminCrmSystem.completionFilter"
          :items="completionToggleItems"
          dense
          class="mr-2"
      />

      <v-btn v-if="$store.state.dashboard.pageData.headerAction"
             @click="$store.state.dashboard.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             dark
             height="35"
      >
        {{ $store.state.dashboard.pageData.headerActionText }}
      </v-btn>



    </template>

    <router-view></router-view>

  </dash-page-new>
</template>

<script>

export default {
  name: "AdminCrm",
  components : {
  },
  data() {
    return {

    }
  },
  computed : {
    navigationItems() {
      return [
        { text : this.$t('AllEvents') , path : '' },
        { text : this.$t('Projects')  , path : 'projects' },
        { text : this.$t('Tickets')   , path : 'events/task' },
        { text : this.$t('Bugs')      , path : 'events/bug' },
      ]
    },
    completionToggleItems() {
      return [
        { text : this.$t('New') , value : 'new' },
        { text : this.$t('Completed') , value : 'completed' },
        { text : this.$t('All') , value : 'all' },
      ]
    },
  },
  mounted() {
    if ( !this.$store.state.adminCrmSystem.completionFilter ) {
      this.$store.state.adminCrmSystem.completionFilter = 'new'
    }
  }

}
</script>

<style scoped>

</style>